
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'
import img5 from '../images/icon/icon5.png'
import img6 from '../images/icon/icon6.png'


const dataBox = [

    {
        id: 1,
        img: img1,
        // title: 'Enhanced Security',
        desc: 'Access 20 million+ decentralized domains alongside the regular web. '
    },
    {
        id: 2,
        img: img2,
        // title: 'Decentralized Trust',
        desc: 'Features domains from leaders like ENS, Unstoppable Domains, and Space.id.'
    },
    {
        id: 3,
        img: img3,
        // title: 'Innovative Features',
        desc: "Grants access to Ethereum founder Vitalik Buterin's Web3 domain."
    },
    {
        id: 4,
        img: img4,
        // title: 'Versatile Applications',
        desc: 'Incentivizes creators and users with Create2Earn, Browse2Earn, Watch2Earn, and Play2Earn. '
    },
    {
        id: 5,
        img: img5,
        // title: 'Versatile Applications',
        desc: 'Includes an ad-blocker for Google Search and YouTube.'
    },
    {
        id: 6,
        img: img6,
        // title: 'Versatile Applications',
        desc: 'Offers simultaneous Google Search and ChatGPT results with one click. '
    },

]

export default dataBox;