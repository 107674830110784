import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/images/animated-icons/homeAnimate.jpg";
import img2 from "../../assets/images/icon/icon-01.png";
import img02 from "../../assets/images/animated-icons/abs5.png";
import img3 from "../../assets/images/animated-icons/abs3.png";

function Banner(props) {
  return (
    <section className="banner">
      <div className="shape"></div>
      <div className="shape right"></div>
      <div className="container big">
        <div className="row" data-aos="fade-up" data-aos-duration="2000">
          <div className="col-xl-6 col-md-12">
            <div className="banner__left">
              <div
                className="block-text xl:ml-20 md:ml-20"
                style={{
                  // userSelect: "none",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h2 className="heading xl:text-[60px] text-[60px] sm:text-[40px] xs:text-[40px] font-bold" style={{fontSize:"50px"}}>
                ⁠AI powered   <br />
                Internet on{" "}
                  <span className="s1 arlo_tm_animation_text_word mb-4">
                  Blockchain.
                  </span>
                  <br />
                </h2>
                <p className="desc">
                  {/* With creators and users, we aspire to a decentralized,
                  user-driven digital future founded on trust in code. */}
                  Along with creators and users, we aspire to a decentralized and humanised digital
future founded on trust in code.
                </p>

                <Link
                  to="/contact"
                  className="action-btn"
                  style={{
                    // userSelect: "none",
                  }}
                >
                  <span>Embark Mission</span>
                </Link>
              </div>
              {/* 
                                <div className="pay" >
                                    <h6>We are Monteno NFT</h6>

                                    <div className="list">
                                        <p>We accept:</p>

                                        <ul>
                                            <li><Link to="#"><span className="icon-logo-01"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-02"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-03"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-04"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-05"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-06"></span></Link></li>
                                        </ul>
                                    </div>
                                </div> */}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="banner__right">
              <div className="image h-3">
                <img src={img1} alt="United Protocol" />
              </div>

              <div
                className="price"
                style={{
                  // userSelect: "none",
                }}
              >
                <div className="icon">
                  <img src={img2} alt="United Protocol" />
                </div>
                <div className="content">
                  <p>Access </p>
                  <h5 className="font-bold">Vitalik.ETH</h5>
                </div>
              </div>
              <div
                className="price1"
                style={{
                  // userSelect: "none",
                }}
              >
                <div className="icon bg-white rounded-[50%]">
                  <img src={img02} alt="United Protocol" width={70} />
                </div>
                <div className="content">
                  <p>
                    Lifetime Web3 <br />
                    identity @ $5
                  </p>
                </div>
              </div>

              <div
                className="owner"
                style={{
                  // userSelect: "none",
                }}
              >
                <div className="image">
                  <img src={img3} alt="United Protocol" />
                </div>
                <div>
                  <h5>Create2Earn</h5>
                  <p>Browse2Earn</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
