import React, { useState } from "react";
import PageTitle from "../components/pagetitle/PageTitle";
import img from "../assets/images/animated-icons/abs1.png";
import Footer2 from "../components/footer/Footer2";
import Footer from "../components/footer/Footer";

import img1 from "../assets/images/animated-icons/dj8.jpg";
import img01 from "../assets/images/animated-icons/blockchain.png";
import img02 from "../assets/images/animated-icons/SEP16.png";
import img3 from "../assets/images/animated-icons/abs3.png";
import { Link } from "react-router-dom";
import Banner2 from "../components/banner/Banner2";
import dataCard from "../assets/fake-data/data-card";
import dataBox from "../assets/fake-data/data-box";
import Speciality from "../components/speciality/Speciality";
import dataPortfolio from "../assets/fake-data/data-portfolio";
import Portfolio from "../components/portfolio/Portfolio";
import dataPortfolio1 from "../assets/fake-data/data-portfolio1";
import About from "../components/about/About";
import Portfolio1 from "../components/portfolio/Portfolio1";
import Portfolio2 from "../components/portfolio/Portfolio2";
import img11 from "../assets/images/animated-icons/table.png";
import img12 from "../assets/images/animated-icons/piiChart.png";
import img51 from "../assets/images/animated-icons/abs1.png";
import { Table } from "react-bootstrap";

const TLD = () => {
  const [dataImg] = useState([
    {
      id: 1,
      img: img11,
      class: "img1",
    },
    // {
    //     id: 2,
    //     img : img2,
    //     class: 'img2'
    // },
    // {
    //     id: 3,
    //     img : img3,
    //     class: 'img3'
    // },
    // {
    //     id: 4,
    //     img : img4,
    //     class: 'img4'
    // },
    // {
    //   id: 51,
    //   img: img51,
    //   class: "img5",
    // },
  ]);
  return (
    <div className="home-1 wrapper">
      <PageTitle title="TLDs" />
      {/* <div className="block-text center ">
        <h6 className="sub-heading ">
          <span>TLD</span>
        </h6>
      </div> */}
      <section
        className="about -mb-20"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="shape"></div>
        <div className="container">
          <div className="row rev">
            <div className="col-xl-6 col-md-12 flex justify-center">
              <div className="about__right flex justify-center items-center">
                <div>
                  <img src={img11} alt="" className="img1" width={700} />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="block-text">
                <h6 className="sub-heading ">
                  <span className="mt-1 ml-4">
                    {/* {dataBlock.subheading} */}
                    {/* UPU<span style={{position:"absolute",fontSize:"12px"}}>TM</span>&nbsp;&nbsp;&nbsp; */}
                    United Protocol TLDs
                  </span>
                </h6>
                <h3 className="heading ">
                  {/* {dataBlock.heading} */}
                  Create a unique Top-Level Domain (TLDs)
                </h3>
                <p className=" w-[90%] mb-2">
                  A top-level domain (TLD) is one of the domains at the highest
                  level in the hierarchical Domain Name System of the Internet
                  after the root domain. <br />
                  e.g. .com and .io
                </p>
                <p className="w-[90%] mb-2">
                  The only specialized TLDs in the world are blog.google and
                  swoosh.nike, which were bought for millions.
                </p>
                <p className="mb-26 w-[90%]">
                  United Names enables you to empower your community by
                  providing them with a Web3 identity on your custom TLD.
                </p>
                <p className="mb-4 text-xl w-[90%]">
                  Be super creative and own a new TLD Starting at $500
                </p>
                <Link to="/contact" className="action-btn">
                  <span>Embark Mission</span>
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TLD;
