import React, { useState } from "react";

import img0 from "../../src/assets/images/animated-icons/favicon.png";

function Layer(props) {
  const [dataBlock] = useState({
    subheading: "United Protocol Ecosystem",
    heading: "Decentralized, Humanised and Secured",
  });
  return (
    <section className="portfolio mt-20">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center"         data-aos="fade-up"
        data-aos-duration="2000">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading xl:text-[50px] lg:text-[50px] md:text-[50px] text-[40px] font-bold ">
                {dataBlock.heading}
              </h3>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center text-center "         data-aos="fade-right"
        data-aos-duration="2000">
            <div
              className="text-2xl font-bold border-x border-t border-green-600"
              style={{
                background:
                  "linear-gradient(180deg, rgba(14, 233, 38, 0.12) 0%, rgba(253, 253, 253, 0) 73.86%)",
                padding: "40px 23px",
                borderRadius: "16px",
                backdropFilter: "blur(4px)",
                marginBottom: "20px",
                width: "70%",
              }}
            >
             DamGo + United Names + Roadmap
            </div>
            <div className="flex justify-center items-center w-full xs:w-1/4 xl:w-full lg:w-full md:w-full sm:w-full" >
              <img src={img0} alt=""  />
            </div>
            <div
              className="text-2xl font-bold border-x border-b border-green-600"
              style={{
                background:
                  "linear-gradient(360deg, rgba(14, 233, 38, 0.12) 0%, rgba(253, 253, 253, 0) 73.86%)",
                padding: "40px 23px",
                borderRadius: "16px",
                backdropFilter: "blur(4px)",
                marginBottom: "20px",
                width: "70%",
              }}
            >
              Layer - 1 Blockchain
            </div>
          </div>
          {/* <div className="col-xl-6 col-md-6">
                            <div className="portfolio__left">
                                {
                                    data.map(idx => (
                                        <div key={idx.id} className="portfolio-box">
                                            <div className="step ">{idx.step}</div>
                                            <div className="icon">
                                                <img src={idx.img} alt="United Protocol" />
                                                    
                                            </div>
                                            <div className="content">
                                                <h5 className="title text-green-800 font-bold">{idx.title}</h5>
                                                <p>{idx.desc}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                                
                            </div>
                        </div> */}

          <div className="col-xl-6 col-md-6">
            <div className="portfolio__right">
              <div
                className="image xl:ml-14 xl:mt-14"
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                {/* <img src={img} alt="United Protocol" width="120%"/> */}
              </div>
            </div>
          </div>
        </div>
      </div>


    </section>
  );
}

export default Layer;
