import React from "react";
import PageTitle from "../components/pagetitle/PageTitle";
import img from "../assets/images/animated-icons/abs1.png";
import Footer2 from "../components/footer/Footer2";
import Footer from "../components/footer/Footer";

import img1 from "../assets/images/animated-icons/sg8.jpg";
import img11 from "../assets/images/animated-icons/table.png";
import img02 from "../assets/images/animated-icons/ab1.png";
import img3 from "../assets/images/animated-icons/abs3.png";
import { Link } from "react-router-dom";
import Banner2 from "../components/banner/Banner2";
import dataCard from "../assets/fake-data/data-card";
import dataBox from "../assets/fake-data/data-box";
import Speciality from "../components/speciality/Speciality";
import dataPortfolio from "../assets/fake-data/data-portfolio";
import Portfolio from "../components/portfolio/Portfolio";
import dataPortfolio1 from "../assets/fake-data/data-portfolio1";
import About from "../components/about/About";
import Portfolio1 from "../components/portfolio/Portfolio1";
import Portfolio3 from "../components/portfolio/Portfolio3";
import Button from "../components/button/Button";

const UnitedNames = () => {
  return (
    <div className="home-1 wrapper">
      <PageTitle title="United Names" />
      <div className="block-text center ">
        <h6 className="sub-heading ">
          <span>Passport to Web3</span>
        </h6>
      </div>

      {/* <Banner2 data={dataCard} /> */}
      <section className="banner ">
        <div className="shape"></div>
        <div className="shape right"></div>
        <div className="container big">
          <div className="row">
            <div className="col-xl-6 col-md-12 ">
              <div className="banner__left">
                <div
                  className="block-text  xl:ml-20 -mt-16 "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h4 className="heading ">
                    Own your Web3 domains
                    <br />
                  </h4>
                  <p className="desc p-0">
                    These are blockchain based NFT domains designed to help you
                    build your Web3 identity. They can be used to launch
                    websites with a pay-once, lifetime access model, enable
                    sending and receiving of crypto, and offer decentralized,
                    single, secure login, among many other potential use cases.
                  </p>
                  <h4 className="heading ">
                    Starting at $5 for lifetime domain and hosting.
                    <br />
                  </h4>

                  <h4 className="heading mb-3 text-center">Book:</h4>
                  <div className="flex gap-2 w-full flex-wrap justify-center">
                    <span
                      style={{
                        background:
                          "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                        color: "white",
                        padding: "4px 14px",
                        borderRadius: "10px",
                        fontSize: "16px",
                      }}
                    >
                      .3
                    </span>
                    <span
                      style={{
                        background:
                          "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                        color: "white",
                        padding: "4px 8px",
                        borderRadius: "10px",
                        fontSize: "16px",
                      }}
                    >
                      .W3
                    </span>
                    <span
                      style={{
                        background:
                          "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                        color: "white",
                        padding: "4px 8px",
                        borderRadius: "10px",
                        fontSize: "16px",
                      }}
                    >
                      .VERSE
                    </span>
                    <span
                      style={{
                        background:
                          "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                        color: "white",
                        padding: "4px 8px",
                        borderRadius: "10px",
                        fontSize: "16px",
                      }}
                    >
                      .TOKEN
                    </span>
                    <span
                      style={{
                        background:
                          "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                        color: "white",
                        padding: "4px 8px",
                        borderRadius: "10px",
                        fontSize: "16px",
                      }}
                    >
                      .DEFI
                    </span>
                    <span
                      style={{
                        background:
                          "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                        color: "white",
                        padding: "4px 8px",
                        borderRadius: "10px",
                        fontSize: "16px",
                      }}
                    >
                      .DAPP
                    </span>
                    <span
                      style={{
                        background:
                          "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                        color: "white",
                        padding: "4px 8px",
                        borderRadius: "10px",
                        fontSize: "16px",
                      }}
                    >
                      .UNITED
                    </span>
                  </div>
                  <h4 className="heading mb-3 text-center mt-2">
                    domain names
                  </h4>

                  <Link to="/contact" className="action-btn mt-4  z-40">
                    <span>Embark Mission</span>
                  </Link>
                  {/* <p className="desc p-0 -mt-6">
                    You have missed out on being part of Web1 by missing .com,
                    don’t miss out on being part of Web3 with United names.
                  </p> */}
                  <div
                    className="icon"
                    style={{
                      animation: "animate-1 3s linear 0s infinite",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={img02} alt="" width={220} />
                  </div>

                  {/* <Link
                    to="/"
                    className="action-btn mb-5"
                    style={{
                      userSelect: "none",
                    }}
                  >
                    <span>Know More</span>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="banner__right py-0">
                <div className="image h-3">
                  <img src={img1} alt="United Protocol" className="img5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="about -mb-20"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="shape"></div>
        <div className="container">
          <div className="row rev">
            <div className="col-xl-6 col-md-12 flex justify-center">
              <div className="about__right flex justify-center items-center">
                <div>
                  <img src={img11} alt="" className="img1" width={700} />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="block-text">
                <h6 className="sub-heading ">
                  <span className="mt-1 ml-4">
                    {/* {dataBlock.subheading} */}
                    {/* UPU<span style={{position:"absolute",fontSize:"12px"}}>TM</span>&nbsp;&nbsp;&nbsp; */}
                    United Protocol TLD
                  </span>
                </h6>
                <h3 className="heading ">
                  {/* {dataBlock.heading} */}
                  Create a unique Top-Level Domain (TLD)
                </h3>
                <p className=" w-[90%] mb-2">
                  A top-level domain (TLD) is one of the domains at the highest
                  level in the hierarchical Domain Name System of the Internet
                  after the root domain. <br />
                  e.g. .com and .io
                </p>
                <p className="w-[90%] mb-2">
                  The only specialized TLDs in the world are blog.google and
                  swoosh.nike, which were bought for millions.
                </p>
                <p className="mb-26 w-[90%]">
                  United Names enables you to empower your community by
                  providing them with a Web3 identity on your custom TLD.
                </p>
                <p className="mb-4 text-xl w-[90%]">
                  Be super creative and own a new TLD Starting at $500
                </p>
                <Link to="/contact" className="action-btn">
                  <span>Embark Mission</span>
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <About/> */}

      {/* <Speciality data={dataBox} /> */}
      <Portfolio3 data={dataPortfolio1} />
      <div className="block-text flex  my-14 text-center gap-1 items-center justify-center">
        <h6 className="sub-heading ">
          <span className="mt-1 ml-4">
            United Names are best experienced on{" "}&nbsp;
          </span>
            <Link
              to="/contact"
              className="text-4xl underline font-bold text-gray-950 hover:text-green-600"
            >
              DamGo
            </Link>
        </h6>
      </div>
      {/* 
      <div className="flex text-2xl mb-4 font-bold text-center gap-1 items-center justify-center">
        <p>United Names are best experienced on </p>
        <Link
          to="/contact"
          className="action-btn text-2xl underline  hover:text-green-400"
        >
          DamGo.
        </Link>
      </div> */}
      {/* <div
        className="block-text center mt-18 xl:flex lg:flex  justify-end "
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        <div className="col-xl-3">
          <h4 className="heading text-[50px] font-bold">
            Blockchain Overview:
            <br />
          </h4>
        </div>
        <div className="md:ml-8 lg:flex ">
          <img
            src="https://1557993060-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FIU9A1Qe88bg2I5PCCyuO%2Fuploads%2FXMe19HFOjWAdRcOFXRg5%2FScreenshot%202023-09-20%20at%2014.39.56.png?alt=media&token=fa3a0df7-732a-4dcb-8224-c1a160df286f"
            alt=""
          />
        </div>
      </div> */}

      <Footer />
    </div>
  );
};

export default UnitedNames;
