import React, { useState, useEffect } from "react";

import { Link, NavLink } from "react-router-dom";
import menus from "../../pages/menu";

import logo from "../../assets/images/logo/logo.png";

import "./styles.scss";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  return (
    <header id="header_main" className={`header ${scroll ? "is-fixed" : ""}`}>
      <div className="container big">
        <div className="row">
          <div className="col-12">
            <div className="header__body">
              <div className="header__logo">
                <Link to="/">
                  <img
                    id="site-logo"
                    src={logo}
                    alt="united protocol"
                    // width={220}
                    className="w-60 sm:w-48 xs:w-48"
                  />
                </Link>
              </div>

              <div className="header__right">
                <nav
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}
                >
                  <ul id="menu-primary-menu" className="menu ">
                    {menus.map((data, idx) => (
                      <li
                        key={idx}
                        onClick={() => handleDropdown(idx)}
                        className={`menu-item  ${
                          data.namesub ? "menu-item-has-children" : ""
                        } ${activeIndex === idx ? "active" : ""}`}
                      >
                        <Link
                          to={data.links}
                          className="border-b-2 border-transparent"
                          style={{ color: "black", fontSize: "20px" }}
                        >
                          {data.name}
                        </Link>
                        {data.namesub && (
                          <ul className="sub-menu parent">
                            {data.namesub.map((submenu) => (
                              <li
                                key={submenu.id}
                                className="menu-item dropdown  "
                              >
                                <NavLink
                                  to={submenu.links}
                                  style={{ fontSize: "15px" }}
                                >
                                  {submenu.sub}
                                </NavLink>

                                {submenu.namesubb && (
                                  <ul
                                    className="dropdown-content  absolute hidden border rounded-md pl-0 -mt-10 visible child"
                                    style={{
                                      width: "150px",
                                      top: "44px",
                                      right: "-150px",
                                      marginLeft: "117px",
                                    }}
                                  >
                                    {submenu.namesubb.map((subsubmenu) => (
                                      <li
                                        key={subsubmenu.id}
                                        className="bg-white  py-2 px-4 m1-4 block whitespace-no-wrap h-12"
                                      >
                                        <Link
                                          to={subsubmenu.links}
                                          className="text-black hover:text-green-800 "
                                        >
                                          {subsubmenu.subb}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
                <div
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}
                >
                  <span></span>
                </div>
              </div>

              <div className="header__action">
                <Link to="#" className="search-btn">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="9.7659"
                      cy="9.76639"
                      r="8.98856"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.0176 16.4849L19.5416 19.9997"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
                <Link to="/contact" className="action-btn">
                  <span>Download DamGo</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    // <header className={`header ${scroll ? 'is-fixed' : ''}`}>
    //         <div className="tf-container">
    //             <div className="row">
    //                 <div className="col-md-12">
    //                     <div id="site-header-inner">
    //                         <div id="site-logo" className="clearfix">
    //                             <div id="site-logo-inner">
    //                                 <Link to="/" rel="home" className="main-logo">
    //                                     <img id="logo_header" className='logo-dark' src='{logodark}' alt="Binasea" />
    //                                     <img id="logo_header" className='logo-light' src='{logo}' alt="Binasea" />
    //                                 </Link>
    //                             </div>
    //                         </div>

    //                        <div className="header-center">
    //                         <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
    //                             <ul id="menu-primary-menu" className="menu">
    //                                 {
    //                                     menus.map((data,idx) => (
    //                                         <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}

    //                                         >
    //                                             <Link to={data.links}>{data.name}</Link>
    //                                             {
    //                                                 data.namesub &&
    //                                                 <ul className="sub-menu">
    //                                                     {
    //                                                         data.namesub.map((submenu) => (
    //                                                             <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
    //                                                         ))
    //                                                     }
    //                                                 </ul>
    //                                             }

    //                                         </li>
    //                                     ))
    //                                 }
    //                             </ul>
    //                         </nav>
    //                        </div>

    //                         <div className="header-right">

    //                             <DarkMode />

    //                             <Link to="/contact" className="tf-button discord"><i className="icon-fl-vt"></i><span>DISCORD</span></Link>
    //                             <Link to="#" className="tf-button connect" data-toggle="modal" data-target="#popup_bid"> <i className="icon-fl-wallet"></i><span>CONNECT</span></Link>
    //                             <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
    //                         </div>

    //                     </div>
    //                 </div>
    //             </div>
    //         </div>

    //     </header>
  );
};

export default Header;
