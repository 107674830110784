import React, { useState } from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap-accordion";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

Faqs.propTypes = {
  data: PropTypes.array,
};

function Faqs(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "FAQs",
    heading: "Frequently Asked Questions",
    desc2: "The FAQs section is a great starting point.",
  });

  const [dataTab] = useState([
    {
      id: 1,
      title: "Cryptocurrency",
    },
    {
      id: 2,
      title: "NFT Token",
    },
    {
      id: 3,
      title: "Collection",
    },
    {
      id: 4,
      title: "Crypto Trading",
    },
  ]);

  return (
    <section className="faq">
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="block-text center"
              data-aos="fade-down"
              data-aos-duration="3000"
            >
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading xl:text-[60px] lg:text-[60px] md:text-[60px] text-[40px] font-bold">
                {dataBlock.heading}
              </h3>
              <p>{dataBlock.desc2}</p>
            </div>

            <div className="faq__main flat-tabs">
              <Tabs>
                {/* <TabList className='menu-tab'>
                                {
                                    dataTab.map(idx => (
                                        <Tab className='fs-14 h6' key={idx.id}>{idx.title}</Tab>
                                    ))
                                }
                                
                            </TabList> */}

                {dataTab.map((idx) => (
                  <TabPanel key={idx.id} className="content-tab text-black">
                    <div className="content-inner">
                      <div className="flat-accordion row">
                        <div className="col-md-6 col-sm-12">
                          {data.slice(0, 3).map((idx) => (
                            <Accordion
                              show={idx.show}
                              key={idx.id}
                              title={
                                <h6 className="text-black flex w-[97%]">
                                  {idx.title}
                                </h6>
                              }
                              className="flat-toggle visible"
                            >
                              <p className="toggle-content text-black">
                                {idx.text}
                              </p>
                            </Accordion>
                          ))}
                        </div>

                        <div className="col-md-6 col-sm-12">
                          {data.slice(3, 6).map((idx) => (
                            <Accordion
                              show={idx.show}
                              key={idx.id}
                              title={
                                <h6 className="text-black flex w-[97%]">
                                  {idx.title}
                                </h6>
                              }
                              className="flat-toggle visible"
                            >
                              <p className="toggle-content text-black">
                                {idx.text}
                                <br />

                                <Link
                                  to="/contact"
                                  className="action-btn"
                                >
                                  <span>{idx.button}</span>
                                </Link>
                              </p>
                            </Accordion>
                          ))}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
