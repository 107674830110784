import React, { useState } from "react";

import img1 from "../../assets/images/animated-icons/dh (14).jpg";
import img5 from "../../assets/images/animated-icons/abs1.png";
import img2 from "../../assets/images/animated-icons/007-record.png";
import img3 from "../../assets/images/animated-icons/007-record.png";
import img4 from "../../assets/images/animated-icons/007-record.png";
import Button from "../button/Button";
import { Link } from "react-router-dom";

function About(props) {
  const [dataImg] = useState([
    {
      id: 1,
      img: img1,
      class: "img1",
    },
    // {
    //     id: 2,
    //     img : img2,
    //     class: 'img2'
    // },
    // {
    //     id: 3,
    //     img : img3,
    //     class: 'img3'
    // },
    // {
    //     id: 4,
    //     img : img4,
    //     class: 'img4'
    // },
    {
      id: 5,
      img: img5,
      class: "img5",
    },
  ]);

  const [dataBlock] = useState({
    subheading: "About us",
    heading: "UPU TM (United Protocol Unit)",
    desc1: "The newest GPU on the block.",
    desc2:
      `We are on a mission to build the Creator's Machine,
      where hardware should not be a limitation for limitless creations.`,
  });
  return (
    <section className="about" data-aos="fade-up" data-aos-duration="2000">
      <div className="shape"></div>
      <div className="container">
        <div className="row rev">
          <div className="col-xl-6 col-md-12">
            <div className="about__right">
              <div className="images">
                {dataImg.map((idx) => (
                  <img
                    key={idx.id}
                    className={idx.class}
                    src={idx.img}
                    alt="United Protocol"
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="block-text">
              <h6 className="sub-heading ">
                <span className="mt-1 ml-4">
                  {/* {dataBlock.subheading} */}
                  UPU<span style={{position:"absolute",fontSize:"12px"}}>TM</span>&nbsp;&nbsp;&nbsp;
                  </span>
              </h6>
              <h3 className="heading ">
                {/* {dataBlock.heading} */}
                United Protocol Unit
              </h3>
              <p className="mb-17 text-xl">{dataBlock.desc1}</p>
              <p className="mb-26 ">{dataBlock.desc2}</p>
              <Link to="/contact" className="action-btn">
                <span>Embark Mission</span>
              </Link>{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
