import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import line from "../../assets/images/background/line-2.png";

Team2.propTypes = {
  data: PropTypes.array,
};

function Team2(props) {
  const { data } = props;
  return (
    <section className="team s2 mt-[-75px]">
      <div className="shape right"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>On a Mission.</span>
              </h6>
              {/* <h3
                className="heading wow xl:text-[60px] lg:text-[60px] md:text-[60px] text-[40px] font-bold"
                data-splitting
              >
                Our Amazing Team
                <br />
                Members
              </h3> */}
            </div>
          </div>

          {data.map((idx) => (
            <div key={idx.id} className="col-xl-3 col-md-4 col-lg-3 col-sm-5 flex justify-center ">
              <div className="team-box">
                <div className="image ">
                  <Link to="/team">
                    <img src={idx.img} alt="United Protocol" />
                  </Link>

                  {/* <ul className="list-social">
                    <li>
                      <Link to="#">
                        <span className="icon-facebook"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-twiter"></span>{" "}
                        
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-plan"></span>
                      </Link>
                    </li>
                  </ul> */}
                </div>
                <div className="content">
                  <Link to="/team" className="h5 name">
                    {idx.name}
                  </Link>
                  <p className="postion">{idx.position}</p>
                  {/* <p className="postion">{idx.work}</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Team2;
