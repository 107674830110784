
import img1 from '../images/teamImages/rudr.png'
import img2 from '../images/teamImages/abhi.png'
import img3 from '../images/teamImages/pravesh.jpg'
import img4 from '../images/teamImages/mark.jpg'
import img5 from '../images/teamImages/sourabh.png'
import img6 from '../images/teamImages/mahim.png'
import img7 from '../images/teamImages/dhruv.png'
import img8 from '../images/teamImages/arnold.jpg'
import img9 from '../images/teamImages/inderjeet.png'
import img10 from '../images/teamImages/jaten-jais.png'
import img11 from '../images/teamImages/rahul-malik.png'
import img12 from '../images/teamImages/dnyandev.jpg'
import img13 from '../images/teamImages/bhavesh.jpg'
import img14 from '../images/teamImages/stevetucker.png'

const dataTeam = [

    {
        id: 1,
        img: img1,
        name: 'Rudr Rishi',
        position: 'Founder & CEO',
        work: "CNBC, ANZ Teleport & Broadcast"
    },
    {
        id: 2,
        img: img2,
        name: 'Abhishek Chhabra',
        position: 'Founder & President',
        work: "NY Tech, Atari and Hyundai"
    },

    {
        id: 3,
        img: img3,
        name: 'Pravesh Bawangade',
        position: 'Founder & CTO ',
        work: "ISRO"
    },

    {
        id: 10,
        img: img10,
        name: 'Jaten Jais',
        position: 'Founder & CMO',
        work: "Lintas, Kia"
    },

    {
        id: 9,
        img: img9,
        name: 'Inderjeet Singh Gill',
        position: 'Founder & CBO',
        work: "Anora hospitality"
    },

    {
        id: 11,
        img: img11,
        name: 'Rahul Malik',
        position: 'Founder & CFO/CRO',
        work: "Anora Hospitality"
    },

    {
        id: 12,
        img: img12,
        name: 'Dnyandev Sawarkar',
        position: 'Tech Lead',
        work: "SVPCET"
    },
    {
        id: 13,
        img: img13,
        name: 'Bhavesh Mahajan',
        position: 'Software Developer',
        work: "SVPCET"
    },

    {
        id: 5,
        img: img5,
        name: 'Sourabh Choudhary',
        position: 'Software Manager',
        work: "IIT - Kharagpur"
    },
    {
        id: 6,
        img: img6,
        name: 'Mahim Jain',
        position: 'Software Developer',
        work: "IIT - Kharagpur"
    },
    {
        id: 7,
        img: img7,
        name: 'Dhruv Gahoi',
        position: 'Software Developer',
        work: "IIT - Kharagpur"
    },

    {
        id: 8,
        img: img8,
        name: 'Arnold Friendland',
        position: 'Advisor',
        work:"Goldman Sachs",
    },
    {
        id: 4,
        img: img4,
        name: 'Mark Warton',
        position: 'Advisor',
        work :"National Australia Bank"
    },

    {
        id: 14,
        img: img14,
        name: 'Steve Tucker',
        position: 'Advisor',
        work: "Apple, Atari, AT&T, Sony, Honeywell, Deloitte"
    },



]

export default dataTeam;