
import img1 from '../images/logo/brand-01.png'
import img2 from '../images/logo/brand-02.png'
import img3 from '../images/logo/brand-03.png'
import img4 from '../images/logo/brand-04.png'
import img5 from '../images/logo/brand-05.png'
import img6 from '../images/logo/brand-06.png'


const dataPartner = [

    {
        id: 1,
        img: img1,
    },
    {
        id: 2,
        img: img2,
    },
    {
        id: 3,
        img: img3,
    },
    {
        id: 4,
        img: img4,
    },
    {
        id: 5,
        img: img5,
    },
    {
        id: 6,
        img: img6,
    },
    

]

export default dataPartner;