import React from "react";
import dataBox from "../assets/fake-data/data-box";
import dataFaqs from "../assets/fake-data/data-faq";
import dataPortfolio from "../assets/fake-data/data-portfolio";
import dataRoadMap from "../assets/fake-data/data-roadmap";
import dataTestimonials from "../assets/fake-data/data-testimonials";
import dataProject from "../assets/fake-data/dataProject";
import dataTeam from "../assets/fake-data/dataTeam";
import About from "../components/about/About";
import Banner from "../components/banner/Banner";
import Create from "../components/create/Create";
import Faqs from "../components/faqs/Faqs";
import Footer from "../components/footer/Footer";
import Portfolio from "../components/portfolio/Portfolio";
import Project from "../components/project/Project";
import Roadmap from "../components/roadmap/Roadmap";
import Speciality from "../components/speciality/Speciality";
import Team from "../components/team/Team";
import Testimonials from "../components/testimonials/Testimonials";
import Team2 from "../components/team/Team2";
import Layer from "../components/Layer";

function Home01(props) {
  return (
    <div className="home-1 wrapper">
      <Banner />

      <About />

      {/* <Speciality data={dataBox} /> */}

      <div className="mb-20 ">
        <div
          className="block-text center -mb-8"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <h6 className="sub-heading">
            <span> DamGo</span>
           </h6>
          <h3 className="heading pd ">
            
            <span className="">
            Watch it to believe it.
            </span>
                
          </h3>
        </div>
        <div
          className="flex justify-center"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <div
            className="mt-14 flex justify-center  p-3 rounded-2xl xl:w-2/3 xl:h-[35rem] md:w-2/3 md:h-[30rem] lg:w-2/3 lg:h-[35rem]"
            style={{
              background:
                "linear-gradient(89.96deg, rgba(5, 166, 0, 0.05) 0.03%, rgba(157, 172, 153, 0.34) 49.67%, rgba(151, 168, 147, 0.15) 99.96%)",
              backdropFilter: "blur(4px)",
            }}
          >
            <iframe
              width="100%"
              // height="100%"
              src="https://www.youtube.com/embed/TKojjn912TM?si=9EJtVMFRtE4OJQHC"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="border-r rounded-xl"
            ></iframe>
          </div>
        </div>
      </div>
      <Portfolio data={dataPortfolio} />

      {/* <Project data={dataProject} /> */}

      {/* <Roadmap  data={dataRoadMap} /> */}
      <Layer />

      {/* <Team2 data={dataTeam} /> */}

      {/* <Testimonials data={dataTestimonials} /> */}

      <Faqs data={dataFaqs} />

      {/* <Create /> */}

      <Footer />
    </div>
  );
}

export default Home01;
