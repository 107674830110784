import React, { useState } from "react";
import PropTypes from "prop-types";

import img from "../../assets/images/animated-icons/abs1.png";

Portfolio.propTypes = {
  data: PropTypes.array,
};

function Portfolio(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "United Protocol Blockchain",
    heading: "Powered by World’s only Layer- 1 blockchain",
  });
  return (
    <section className="portfolio" data-aos="fade-up" data-aos-duration="2000">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading mt-9">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading mt-8 xl:text-[60px] lg:text-[60px] md:text-[60px] text-[40px] font-bold">
                {/* {dataBlock.heading} */}
                <span className="text-xl text-gray-800">Powered by </span>
                <br />
                
                {/* <br /> */}
                <span className="heading">
                  World’s only Layer 1 built for financial <br /> and non financial
                  transactions.
                </span>
              </h3>
            </div>
          </div>
          <div className="col-xl-6 col-md-6">
            <div className="portfolio__left">
              {data.map((idx) => (
                <div key={idx.id} className="portfolio-box">
                  <div className="step ">{idx.step}</div>
                  <div className="flex justify-center items-center">
                    <div className="icon">
                      <img src={idx.img} alt="United Protocol" />
                    </div>
                    <div className="content">
                      <h5 className="title text-green-800 text-xl font-bold">
                        {idx.title}
                      </h5>
                      <p>{idx.desc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-xl-6 col-md-6">
            <div className="portfolio__right">
              <div
                className="image xl:ml-14 mt-0"
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <img src={img} alt="United Protocol" width="120%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
