import React, { useState } from "react";
import PropTypes from "prop-types";

import img from "../../assets/images/animated-icons/SEP5.png";
import dataPortfolio2 from "../../assets/fake-data/data-portfolio2";

Portfolio2.propTypes = {
  data: PropTypes.array,
};

function Portfolio2(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "United Protocol Ecosystem",
    heading:
      "United Protocol Ecosystem",
  });
  return (
    <section className="portfolio" data-aos="fade-up" data-aos-duration="2000">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              {/* <h3 className="heading pd xl:text-[50px] lg:text-[50px] md:text-[40px] text-[30px] font-bold">
                {dataBlock.heading}
              </h3> */}
            </div>
          </div>
          <div className="col-xl-6 col-md-6">
            <div className="portfolio__left">
              {dataPortfolio2.map((idx) => (
                <div key={idx.id} className="portfolio-box">
                  <div className="step ">{idx.step}</div>
                  <div className="flex justify-center items-center">
                    <div className="icon">
                      <img src={idx.img} width={50} alt="United Protocol" />
                    </div>
                    <div className="content">
                      <h5 className="title text-green-800 font-bold text-[1.8rem]">
                        {idx.title}
                      </h5>
                      <p>{idx.desc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-xl-6 col-md-6">
            <div className="portfolio__right">
              <div
                className="image xl:ml-14 mt-0"
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <img
                  src={img}
                  alt="United Protocol"
                  width="100%"
                  className="-mt-16"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio2;
