import React, { useState } from "react";
import PageTitle from "../components/pagetitle/PageTitle";
import img from "../assets/images/animated-icons/abs1.png";
import Footer2 from "../components/footer/Footer2";
import Footer from "../components/footer/Footer";

import img1 from "../assets/images/animated-icons/dj8.jpg";
import img01 from "../assets/images/animated-icons/blockchain.png";
import img02 from "../assets/images/animated-icons/SEP16.png";
import img3 from "../assets/images/animated-icons/abs3.png";
import { Link } from "react-router-dom";
import Banner2 from "../components/banner/Banner2";
import dataCard from "../assets/fake-data/data-card";
import dataBox from "../assets/fake-data/data-box";
import Speciality from "../components/speciality/Speciality";
import dataPortfolio from "../assets/fake-data/data-portfolio";
import Portfolio from "../components/portfolio/Portfolio";
import dataPortfolio1 from "../assets/fake-data/data-portfolio1";
import About from "../components/about/About";
import Portfolio1 from "../components/portfolio/Portfolio1";
import Portfolio2 from "../components/portfolio/Portfolio2";
import img11 from "../assets/images/animated-icons/blockchain.png";
import img12 from "../assets/images/animated-icons/piiChart.png";
import img51 from "../assets/images/animated-icons/abs1.png";
import { Table } from "react-bootstrap";

const UnitedLayer1 = () => {
  const [dataImg] = useState([
    {
      id: 1,
      img: img11,
      class: "img1",
    },
    // {
    //     id: 2,
    //     img : img2,
    //     class: 'img2'
    // },
    // {
    //     id: 3,
    //     img : img3,
    //     class: 'img3'
    // },
    // {
    //     id: 4,
    //     img : img4,
    //     class: 'img4'
    // },
    // {
    //   id: 51,
    //   img: img51,
    //   class: "img5",
    // },
  ]);
  return (
    <div className="home-1 wrapper">
      <PageTitle title="United Layer 1" />
      <div className="block-text center ">
        <h6 className="sub-heading ">
          <span>Think Beyond</span>
        </h6>
      </div>

      {/* <Banner2 data={dataCard} /> */}
      <section className="banner">
        <div className="shape"></div>
        <div className="shape right"></div>
        <div className="container big">
          <div className="row">
            <div className="col-xl-6 col-md-12 ">
              <div className="banner__left">
                <div
                  className="block-text  xl:ml-20 -mt-16 "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h4 className="heading text-[40px] font-bold">
                    World’s only Layer 1 blockchain built for financial and non
                    financial transactions.
                    <br />
                  </h4>
                  <p className="desc p-0">
                    The United Protocol blockchain represents the near future in
                    the world of blockchain technology. It stands as the world's
                    sole Layer 1 blockchain, meticulously engineered to cater to
                    both financial and non-financial <br /> transactions.
                  </p>
                  {/* <p className="desc -mt-8 p-0">
                    This distinction sets it apart as a unique and powerful
                    player in the blockchain realm, poised to ascend to the
                    ranks of the top 31 cryptocurrencies worldwide.
                  </p> */}
                  <Link
                    to="https://docs.unitedprotocol.org/products/up-blockchain"
                    className="action-btn"
                  >
                    <span>Read More</span>
                  </Link>
                  <div
                    className="icon"
                    style={{
                      animation: "animate-1 3s linear 0s infinite",
                    }}
                  >
                    <img src={img02} alt="" width={220} />
                  </div>

                  {/* <Link
                    to="/"
                    className="action-btn mb-5"
                    style={{
                      userSelect: "none",
                    }}
                  >
                    <span>Know More</span>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="banner__right py-0">
                <div className="image h-3">
                  <img src={img1} alt="United Protocol" className="img5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <About/> */}

      {/* <Speciality data={dataBox} /> */}

      <section
        className="about -mt-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="shape"></div>
        <div className="container">
          <div className="row rev">
            <div className="col-xl-6 col-md-12">
              <div className="about__right xl:mt-28">
                <div className="">
                  <img src={img11} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="block-text">
                <h6 className="sub-heading ">
                  <span>UP Layer-1 Blockchain</span>
                </h6>
                <p className="mb-17 text-xl">
                  {/* {dataBlock.desc1} */}
                  The United Protocol Blockchain serves as the formidable
                  backbone that empowers a wide array of products and services
                  within the United Protocol Ecosystem.
                </p>
                <p className="mb-26 ">{/* {dataBlock.desc2} */}</p>
                <Link
                  to="/contact"
                  className="action-btn"
                  style={{ position: "absolute", zIndex: "9999999999" }}
                >
                  <span>Embark Mission</span>
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className="about -mt-20 -mb-20"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="shape"></div>
        <div className="container">
          <div className="row rev flex justify-center items-center">
            <div className="col-xl-6 col-md-12">
              <div className="block-text xl:ml-14 lg:ml-14 ">
                <h6 className="sub-heading ">
                  <span>Ever evolving use cases</span>
                </h6>
            
                <div className="flat-accordion row ">
                  <ul className="col-md-6 col-sm-12 text-[20px]">
                    <li style={{ listStyleType: "disc" }}>
                      Incentivizing time
                    </li>
                    <li style={{ listStyleType: "disc" }}>User searches</li>
                    <li style={{ listStyleType: "disc" }}>Browser History</li>
                    <li style={{ listStyleType: "disc" }}>Gifting</li>
                    <li style={{ listStyleType: "disc" }}>Flipping NFTs</li>
                  </ul>
                  <ul className="col-md-6 col-sm-12 text-[20px]">
                    <li style={{ listStyleType: "disc" }}>
                      Asset tokenization
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      Digital Advertising Reports
                    </li>
                    <li style={{ listStyleType: "disc" }}>Supply Chain</li>
                    <li style={{ listStyleType: "disc" }}>Analytics</li>
                    <li style={{ listStyleType: "disc" }}>...and many more</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12 ">
              <div className="about__right xl:mt-28">
                <div className="flex justify-center xl:mr-36">
                  <img src={img12} alt="" width={500} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="block-text flex justify-center mt-20">
        <h6 className="sub-heading ">
          <span>Comparison</span>
        </h6>
      </div>
      <div className=" flex justify-center ">
        <div className="xl:w-[60%] md:w-[90%] sm:w-[90%] xs:w-[90%] " style={{borderRadius:"2rem",overflow:"hidden",border:"1px solid green"}}>
          <Table bordered size="md" responsive className="mb-0">
            <thead>
              <tr
                style={{
                  background:
                    "linear-gradient(264.28deg, #c7ffd0 -38.2%, rgba(251, 252, 250, 0.922) 103.12%), linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
                }}
              >
                <th></th>
                <th>Ethereum</th>
                <th>Bitcoin</th>
                <th>United Layer 1</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Energy Efficiency</td>
                <td>NO-GPU Optimal</td>
                <td>NO-ASIC Optimal</td>
                <td>
                  <span
                    style={{
                      background:
                        "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                      borderRadius: "18px",
                      padding: "6px 8px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Ues-ARM Optimal
                  </span>
                </td>
              </tr>
              <tr>
                <td>Safety Threshold</td>
                <td>34%</td>
                <td>51%</td>
                <td>
                  <span
                    style={{
                      background:
                        "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                      borderRadius: "18px",
                      padding: "6px 8px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    33%
                  </span>
                </td>
              </tr>
              <tr>
                <td>TPS</td>
                <td>100k</td>
                <td>7</td>
                <td>
                  <span
                    style={{
                      background:
                        "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                      borderRadius: "18px",
                      padding: "6px 8px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    10k
                  </span>
                </td>
              </tr>
              <tr>
                <td>Sybil Protection</td>
                <td>Proof of Stake</td>
                <td>Proof of Work</td>
                <td>
                  <span
                    style={{
                      background:
                        "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                      borderRadius: "18px",
                      padding: "6px 8px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Proof of Stake
                  </span>
                </td>
              </tr>
              {/* <tr>
              <td>SDGs</td>
              <td>No</td>
              <td>No</td>
              <td>
                <span style={{background: "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",borderRadius:"18px",padding:"6px 8px",width:"100%",textAlign:"center"}}>

                At Core
                </span>
                </td>
            </tr> */}
              <tr>
                <td>Transactional finality</td>
                <td>15 min</td>
                <td>60 min</td>
                <td>
                  <span
                    style={{
                      background:
                        "linear-gradient(264.28deg, #a5f1b6 -38.2%, #0ac74c 103.12%)",
                      borderRadius: "18px",
                      padding: "6px 8px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    6 sec
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="mb-8 mt-28">
        <Portfolio2 data={dataPortfolio1} />
      </div>

      {/* <div
        className="block-text center mt-18 flex flex-col justify-center items-center"
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        <div className="">
          <h4 className="heading text-[50px] sm:text-[30px] xs:text-[30px] font-bold">
            Blockchain Overview
            <br />
          </h4>
        </div>
        <div className="">
          <img src={img01} alt="" />
        </div>
      </div> */}

      <Footer />
    </div>
  );
};

export default UnitedLayer1;
