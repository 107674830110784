


const dataFaqs = [
    {
        id: 1,
        title: 'What is United Protocol?',
        text : 'United Protocol is a Web3 believers ecosystem for creators and users who envision adecentralized, user-driven digital future founded on trust in code.',
        show: ''
    
    },
    {
        id: 2,
        title: 'What are the key features of United Protocol? ',
        text : "Currently, the offerings include a Web3 and AI browser, DamGo, which can access 20 million Web3 domains sold by projects like ENS and Unstoppable Domains. Creators can leverage the power of customized TLDs like .sui, .aptos, etc. All these offerings are decentralized by the UP Layer-1 Blockchain.",
        show: ''
    
    },
    {
        id: 3,
        title: "How can businesses integrate with United Protocol's technology? (Coming soon)",
        text : "Businesses can integrate with United Protocol by leveraging its blockchain infrastructure and incorporating its features into their systems. They can benefit from enhanced security, transparency, and efficiency in their digital operations.",
        show: ''
    
    },
    {
        id: 4,
        title: "What are the rewards for participating in the United Protocol ecosystem? ",
        text : "Believers in the United Protocol ecosystem, such as creators, users, validators, and stakeholders, can incentivize their time with Create2Earn, Browse2Earn, and Play2Earn. These incentives can also include token rewards for staking, contributing to network security, and supporting the ecosystem's growth",
        show: ''
    
    },
    {
        id: 5,
        title: 'How can I get involved with United Protocol or learn more?',
        text : 'To get involved or learn more about United Protocol, you can visit the official website,join the community channels, explore their documentation, or contact the team for further information and guidance. ',
        show: ''
    
    },
    {
        id: 6,
        title: "Can individuals participate in the United Protocol ecosystem? ",
        text : 'Yes, individuals can participate in the United Protocol ecosystem. They can utilize the platform for creation, browsing, secure digital transactions, engage with decentralized applications (dApps), and potentially earn rewards through staking. ',
        show: '',
        button: "Embark mission"
    
    },
    // {
    //     id: 7,
    //     title: 'What are the rewards for participating in the United Protocol ecosystem? ',
    //     text : "Participants in the United Protocol ecosystem, such as validators and stakeholders, may be eligible for rewards. These can include token rewards for staking, contributing to network security, and supporting the ecosystem's growth. ",
    //     show: ''
    
    // },
    // {
    //     id: 8,
    //     title: 'How can I get involved with United Protocol or learn more? ',
    //     text: "To get involved or learn more about United Protocol, you can visit their official website, join their community channels, explore their documentation, or reach out to their team for further information and guidance. ",
    //     show: ''
    
    // },


   

   
]

export default dataFaqs;