


import About from "./About";
import AdvisorsBackers from "./AdvisorsBackers";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Coinomics from "./Coinomics";
import Contact from "./Contact";
import DamGo from "./DamGo";
import FAQ from "./FAQ";
import HelpCenter from "./HelpCenter";
import Home01 from "./Home01";
import Home02 from "./Home02";
import Home03 from "./Home03";
import Nfts from "./Nfts";
import ParticipantAssets from "./ParticipantAssets";
import Partners from "./Partners";
import RoadMap from "./RoadMap";
import TLD from "./TLD";
import Team from "./Team";
import UnitedLayer1 from "./UnitedLayer1";
import UnitedNames from "./UnitedNames";
import VisionsMission from "./VisionsMission";





const routes = [
  { path: '/', component: <Home01 />},
  { path: '/home-v2', component: <Home02 />},
  { path: '/home-v3', component: <Home03 />},

  { path: '/nfts', component: <Nfts />},
  { path: '/blog', component: <Blog />},
  { path: '/faqs', component: <FAQ />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/visions-mission', component: <VisionsMission />},
  { path: '/help-center', component: <HelpCenter />},
  { path: '/participants-assets', component: <ParticipantAssets />},
  { path: '/advisors-backers', component: <AdvisorsBackers />},
  { path: '/partners', component: <Partners />},
  { path: '/about', component: <About />},
  { path: '/road-map', component: <RoadMap />},
  { path: '/team', component: <Team />},
  { path: '/contact', component: <Contact />},
  { path: '/damgo', component: <DamGo />},
  { path: '/united-layer-1', component: <UnitedLayer1 />},
  { path: '/united-names', component: <UnitedNames />},
  { path: '/tld', component: <TLD />},
  // { path: '/coinomics', component: <Coinomics />},



]

export default routes;