import React from "react";
import PageTitle from "../components/pagetitle/PageTitle";
import img from "../assets/images/animated-icons/abs1.png";
import Footer2 from "../components/footer/Footer2";
import Footer from "../components/footer/Footer";

import img1 from "../assets/images/animated-icons/gy5.jpg";
import img02 from "../assets/images/animated-icons/sep1.png";
import img3 from "../assets/images/animated-icons/abs3.png";
import { Link } from "react-router-dom";
import Banner2 from "../components/banner/Banner2";
import dataCard from "../assets/fake-data/data-card";
import dataBox from "../assets/fake-data/data-box";
import Speciality from "../components/speciality/Speciality";
import dataPortfolio from "../assets/fake-data/data-portfolio";
import Portfolio from "../components/portfolio/Portfolio";
import dataPortfolio1 from "../assets/fake-data/data-portfolio1";
import About from "../components/about/About";
import Portfolio1 from "../components/portfolio/Portfolio1";

const DamGo = () => {
  return (
    <div className="home-1 wrapper">
      <PageTitle title="DamGo" />
      <div className="block-text center ">
        <h6 className="sub-heading ">
          <span>A Truly AI & Web3 Browser</span>
        </h6>
      </div>

      {/* <Banner2 data={dataCard} /> */}
      <section className="banner mb-0">
        <div className="shape"></div>
        <div className="shape right"></div>
        <div className="container big">
          <div className="row">
            <div className="col-xl-6 col-md-12 ">
              <div className="banner__left">
                <div
                  className="block-text  xl:ml-20 -mt-16 "
                  style={{
                    // userSelect: "none",
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h4 className="heading ">
                    The newest browsing experience.
                    <br />
                  </h4>
                  <p className="desc p-0">
                    DamGo Browser accesses 20M+ decentralized domains along with
                    current web, empowering creators and users by incentivizing
                    their time.
                  </p>
                  <p className="desc p-0 -mt-4">
                    Enjoy ad-blocking, instant Google Search, and ChatGPT
                    results with one click.
                  </p>
                  <div
                    className="icon"
                    style={{
                      animation: "animate-1 3s linear 0s infinite",
                    }}
                  >
                    <img src={img02} alt="" width={220} />
                  </div>

                  {/* <Link
                    to="/"
                    className="action-btn mb-5"
                    style={{
                      userSelect: "none",
                    }}
                  >
                    <span>Know More</span>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="banner__right py-0">
                <div className="image h-3">
                  <img src={img1} alt="United Protocol" className="img5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <About/> */}
      <div
        className="block-text center mt-14"
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        <h6 className="sub-heading">
          <span> DamGo</span>
        </h6>
        <h3 className="heading  xl:text-[50px] lg:text-[50px] md:text-[50px] text-[40px] font-bold">
          
        A Truly AI & Web3 Browser
        </h3>
      </div>

      <div
        className="flex justify-center mb-4"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div
          className="my-14 flex justify-center  p-3 rounded-2xl xl:w-2/3 xl:h-[35rem] md:w-2/3 md:h-[30rem] lg:w-2/3 lg:h-[35rem]"
          style={{
            background:
              "linear-gradient(89.96deg, rgba(5, 166, 0, 0.05) 0.03%, rgba(157, 172, 153, 0.34) 49.67%, rgba(151, 168, 147, 0.15) 99.96%)",
            backdropFilter: "blur(4px)",
          }}
        >
          <iframe
            width="100%"
            // height="100%"
            src="https://www.youtube.com/embed/TKojjn912TM?si=9EJtVMFRtE4OJQHC"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            className="border-r rounded-xl"
          ></iframe>
        </div>
      </div>

      <Speciality data={dataBox} />
      <Portfolio1 data={dataPortfolio1} />



      <Footer />
    </div>
  );
};

export default DamGo;
