const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
    // icon: "https://img.icons8.com/glyph-neue/64/40C057/home--v1.png",
  },
  // {
  //     id: 2,
  //     name: 'About',
  //     links: '/about',

  // },
  {
    id: 3,
    name: "Products",
    // icon: "https://img.icons8.com/dotty/80/40C057/shopping-cart.png",
    // links: '/products',
    namesub: [
      {
        id: 1,
        sub: "DamGo",
        links: "/damgo",
      },
      {
        id: 2,
        sub: "United Layer-1",
        links: "/united-layer-1",
      },
      {
        id: 3,
        sub: "United Names",
        // links: "/united-names",
        namesubb: [
          {
            id: 1,
            subb: "TLDs",
            links: "/tld",
          },
          {
            id: 2,
            subb: "Domains",
            links: "/united-names",
          },
        ],
      },
      // {
      //   id: 4,
      //   sub: "UP TLDs",
      //   links: "/",
      // },
      {
        id: 5,
        sub: "United Explorer",
        // links: 'https://explorer.unitedprotocol.org/'
        links: "/",
      },
    ],
  },
  {
    id: 5,
    name: "Roadmap",
    links: "/road-map",
  },
  // {
  //   id: 6,
  //   name: "Coinomics",
  //   links: "/coinomics",
  // },
  {
    id: 7,
    name: "Docs",
    links: "https://docs.unitedprotocol.org/",
  },
  {
    id: 8,
    name: "Connect",
    links: "/contact",
  },
  // {
  //     id: 6,
  //     name: 'More',
  //     // links: '/more',
  //     namesub: [
  //         {
  //             id: 1,
  //             sub: 'Documentation',
  //             links: 'http://docs.unitedprotocol.org/'
  //         },
  //         {
  //             id: 2,
  //             sub: 'FAQs',
  //             links: '/faqs'
  //         },
  //         {
  //             id: 3,
  //             sub: 'Contacts',
  //             links: '/contact'
  //         },

  //     ]

  // },
];

export default menus;
