import React from 'react'
import Faqs from '../components/faqs/Faqs'
import dataFaqs from '../assets/fake-data/data-faq'
import Footer from '../components/footer/Footer'

function FAQ() {
  return (
    <div className='wrapper'>
          <Faqs data={dataFaqs} />
          <Footer />

    </div>
  )
}

export default FAQ