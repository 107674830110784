
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataPortfolio = [

    {
        id: 1,
        img: img1,
        step: "30",
        title: 'Number of Layer 1 projects on CoinMarketCap.',
        // desc: 'Suspendisse tristique neque a lorem placerat pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos'
    },
    {
        id: 2,
        img: img2,
        step : "09",
        title: 'Open for developers to write smart contracts and build dApps.',
        // desc: 'Suspendisse tristique neque a lorem placerat pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos'
    },
    {
        id: 3,
        img: img3,
        step: "00",
        title: 'Think beyond financial transactions.',
        // desc: 'Suspendisse tristique neque a lorem placerat pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos'
    },
    // {
    //     id: 4,
    //     img: img4,
    //     title: 'Sell your NFT',
    //     desc: 'Suspendisse tristique neque a lorem placerat pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos'
    // },


]

export default dataPortfolio;