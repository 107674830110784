
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataPortfolio2 = [

    {
        id: 1,
        img: img1,
        // step: "30",
        title: 'DamGo Browser',
        // desc: 'Suspendisse tristique neque a lorem placerat pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos'
    },
    {
        id: 2,
        img: img2,
        // step : "09",
        title: 'UP Names',
        // desc: 'Suspendisse tristique neque a lorem placerat pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos'
    },
    {
        id: 3,
        img: img3,
        // step: "00",
        title: 'UP TLDs',
        // desc: 'Suspendisse tristique neque a lorem placerat pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos'
    },
    {
        id: 4,
        img: img4,
        title: 'UP Explorer',
        // desc: 'Blockchain Developers, Entrepreneurs and Innovators.'
    },


]

export default dataPortfolio2;