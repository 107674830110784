import React, { useState } from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import Counter from "../components/counter/Counter";
import Footer from "../components/footer/Footer";
import Faqs from "../components/faqs/Faqs";
import dataFaqs from "../assets/fake-data/data-faq";

import img1 from "../assets/images/layouts/about-01.png";
import img2 from "../assets/images/layouts/about-02.png";
import img3 from "../assets/images/layouts/about-03.png";
import img4 from "../assets/images/layouts/about-04.png";
import img5 from "../assets/images/layouts/about-05.png";
import img from "../assets/images/animated-icons/abs1.png";

function VisionsMission(props) {
  const [dataImg] = useState([
    {
      id: 1,
      img: img1,
      class: "img1",
    },
    {
      id: 2,
      img: img2,
      class: "img2",
    },
    {
      id: 3,
      img: img3,
      class: "img3",
    },
    {
      id: 4,
      img: img4,
      class: "img4",
    },
    {
      id: 5,
      img: img5,
      class: "img5",
    },
  ]);
  return (
    <div className="page-visions wrapper">
      <PageTitle title="Visions & Mission" />
      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="block-text">
                <h6 className="sub-heading">
                  <span>Visions & Mission</span>
                </h6>
                <h3
                  className="heading wow text-[40px] font-bold"
                  data-splitting
                >
                  Our Vision
                </h3>
                <p className="mb-17">
                  Cyfonii aims to build a virtual reality environment, a real
                  metaverse for business activities, where all users can easily
                  bring their business activities to the network environment,
                  conduct the comprehensive digital transformation of business
                  operations, increase outstanding efficiency with the maximum
                  support of technologies.
                </p>
              </div>
              <div className="block-text">
                <h6 className="sub-heading">
                  <span>Visions & Mission</span>
                </h6>
                <h3
                  className="heading wow text-[40px] font-bold"
                  data-splitting
                >
                  Our Mission
                </h3>
                <p>
                  Cyfonii is conceptualized almost like the development of the
                  digital society and digital economy of the world according to
                  the comprehensive digital transformation model (Digital
                  Transformation) in the period of Industry 4.0 taking place
                  very strongly all over the world starting from the
                  digitization period (Digitization) to the goal of building a
                  virtual super universe (Metaverse) is being shaped.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="about__right">
                <div className="images">
                  {dataImg.map((idx) => (
                    <img
                      key={idx.id}
                      className={idx.class}
                      src={idx.img}
                      alt="cyfonii"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Counter />
      {/* <section className="watch-video">
        <div className="shape"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="block-text center">
                <h3 className="heading">
                  See how we
                  <br />
                  solve problems
                </h3>
              </div>

              <div className="watch-video__main">
                <div className="main">
                  <div className="wrap-video">
                    <a
                      href="https://www.youtube.com/watch?v=i7EMACWuErA"
                      className="popup-youtube"
                    >
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.853 7.25168C16.2247 8.01369 16.2247 9.9863 14.853 10.7483L2.97129 17.3493C1.63822 18.0899 -7.9231e-07 17.1259 -7.25651e-07 15.601L-1.48576e-07 2.39903C-8.19178e-08 0.874059 1.63822 -0.0898765 2.97129 0.650714L14.853 7.25168Z"
                          fill="#D9D9D9"
                        />
                        <path
                          d="M14.853 7.25168C16.2247 8.01369 16.2247 9.9863 14.853 10.7483L2.97129 17.3493C1.63822 18.0899 -7.9231e-07 17.1259 -7.25651e-07 15.601L-1.48576e-07 2.39903C-8.19178e-08 0.874059 1.63822 -0.0898765 2.97129 0.650714L14.853 7.25168Z"
                          fill="url(#paint0_linear_787_6121)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_787_6121"
                            x1="43.9319"
                            y1="31.9348"
                            x2="38.9828"
                            y2="-12.545"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.164688" stopColor="#DEC7FF" />
                            <stop offset="0.855177" stopColor="#5C27FE" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </a>
                  </div>

                  <h5>Watch video</h5>
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/nXbQ-dItErg?si=XUyBgkR1Xlqn6-JQ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>

                  <img src={img} alt="Cyfonii" width="60%"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div className="flex justify-center">

      <div className="mt-14 flex justify-center  p-3 rounded-2xl xl:w-2/3 xl:h-[35rem] md:w-2/3 md:h-[35rem] lg:w-2/3 lg:h-[35rem]" style={{
        background: "linear-gradient(89.96deg, rgba(5, 166, 0, 0.05) 0.03%, rgba(157, 172, 153, 0.34) 49.67%, rgba(151, 168, 147, 0.15) 99.96%)",
        backdropFilter: "blur(4px)"
      }}>
        <iframe
          width="100%"
          // height="100%"
          src="https://www.youtube.com/embed/8Rg5wTy3Wc0?si=yiJheoDReoWxUd1K"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="border-r rounded-xl"
        ></iframe>
      </div>
      </div>

      <Faqs data={dataFaqs} />

      <Footer />
    </div>
  );
}

export default VisionsMission;
