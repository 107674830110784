import React , {useState} from 'react';



function Speciality(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subheading: 'Features',
            heading: 'Speciality to Features',
            // desc: 'United Protocol is the premier marketplace for nifties, which are digital items you can truly own for yourself' ,
            
        }
    )
    return (
        <section 
        className="speciality"
        // className="block-text center"
        data-aos="fade-up"
        data-aos-duration="2000">
                <div className="shape right"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                {/* <h3 className="heading pd text-[60px] font-bold">{dataBlock.heading}</h3> */}
                                    {/* <p className="">{dataBlock.desc}</p> */}
                            </div>
                        </div>
                        {
                            data.map((idx) => (
                                <div key={idx.id} className="col-xl-4 col-md-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img src={idx.img} alt="United Protocol" />
                                        </div>
                                        <h5 className="title text-black">{idx.title}</h5>
                                        <p>{idx.desc}</p>
                                        <h3 className="number text-5xl font-semibold">0{idx.id}</h3>
                                    </div>
                                </div>
                                
                            ))
                        }
                        
                        
                    </div>
                </div>
            </section>

    );
}

export default Speciality;