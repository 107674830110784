import React, { useState } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCoverflow, Autoplay, Pagination, Navigation } from "swiper";
import Button from "../button/Button";
import img from "../../assets/images/animated-icons/asm2.jpg";
import About from "../about/About";

Banner2.propTypes = {
  data: PropTypes.array,
};

function Banner2(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "DamGo AI",
    heading: "Most Popular Collections",
    desc: "United Protocol is the premier marketplace for nifties, which are digital items you can truly own for yourself",
  });
  return (

    <section className="banner s2">
      <div className="shape"></div>
      <div className="shape right"></div>
      <div className="container" data-aos="fade-down" data-aos-duration="2000">
        <div className="row">
          <div className="col-12">
            <div 
            className="block-text center "
            
            >
              <h6 className="sub-heading ">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h2 className="heading  text-[60px] font-bold mb-4">
                DamGo Browser
              </h2>
              <h3 className="heading  text-[30px]  ">
                The newest browsing experience powered by Web3 and AI.
              </h3>
              {/* <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-8 ">
                    <div className="mt-8">

                  <p className="mb-34">
                    DamGo Browser offers a unique browsing experience, combining
                    20 million+ decentralized domains with the regular web. It's
                    user-centric, incentivizing creators and users, and features
                    an ad-blocker for Google Search and YouTube. Plus, it
                    provides instant access to both Google Search and ChatGPT
                    results in a single click.
                  </p>

                  <Button title="Download DamGo" link="/" />
                    </div>
                </div>
                <div className="image h-3 col-xl-3 col-lg-3 col-md-3">
                  <img src={img} alt="" style={{borderRadius:"35px"}} />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <About /> */}
    </section>
  );
}

export default Banner2;
