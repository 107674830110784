const dataRoadMap = [
  {
    id: 1,
    time: "Oct’23",
    // title1: 'Layer 1 Blockchain ',
    // title2: 'Testnet launch ',
    // title3: 'Validator program ',
    subMap: [
      {
        id: 1,
        subtitle: "DamGo - Resolve 20mn Web3 domains",
      },
      {
        id: 2,
        subtitle: "DamGo - Google and ChatGPT same screen",
      },
      {
        id: 3,
        subtitle: "DamGo - Google Search and YouTube Adblocker",
      },
      {
        id: 4,
        subtitle: "DamGo - User incentivation",
      },
      {
        id: 5,
        subtitle: "United Layer 1 mainnet (internal)",
      },
      {
        id: 6,
        subtitle: "United Names (TLDs and Names)",
      },
      // {
      //   id: 7,
      //   subtitle: "United TLDs",
      // },
    ],
    class: "",
  },
  {
    id: 2,
    time: "Jan’24",
    title1: "AI based Next Gen Smart Contract Writing ",
    title2: "“.united“ TLD and names",
    title3: false,
    // desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam enim, scelerisque sit amet diam ut, molestie ultrices nisi. Suspendisse in ipsum ante. Ut rhoncus ligula dictum gravida.',
    subMap: [
      {
        id: 1,
        subtitle: "DamGo - Pixel Streaming",
      },
      {
        id: 2,
        subtitle: "United Layer 1 - Bridging (BNB, SUI, Aptos)",
      },
      {
        id: 3,
        subtitle: "United Names - NIP (Names Interoperatibility Protocol)",
      },
      {
        id: 4,
        subtitle: "Web3 SSL",
      },
    ],
    class: "right",
  },

  {
    id: 3,
    time: "Jun’24 ",
    title1: "AI based Next Gen Smart Contract Audit",
    title2: "Proof of ownership with ZK",
    title3: false,
    // desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam enim, scelerisque sit amet diam ut, molestie ultrices nisi. Suspendisse in ipsum ante. Ut rhoncus ligula dictum gravida.',
    subMap: [
      {
        id: 1,
        subtitle: "Gas Flare Web Hosting and Storage",
      },
      {
        id: 2,
        subtitle: "United Wallet - Iris scan approved",
      },
      {
        id: 3,
        subtitle: "DamGo - Apple Watch, Apple Vision Pro and Humane",
      },
    ],
    class: "",
  },

  {
    id: 4,
    time: "Dec’24",
    title1: "Result & Final Report",
    title2: false,
    title3: false,
    // desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam enim, scelerisque sit amet diam ut, molestie ultrices nisi. Suspendisse in ipsum ante. Ut rhoncus ligula dictum gravida.',
    subMap: [
      {
        id: 1,
        subtitle: "United Secure (Cybersecurity)",
      },
      {
        id: 2,
        subtitle: "United Layer 1 - AI smart contract (with partners)",
      },
      {
        id: 3,
        subtitle: "DamGo - BYSO (Before You Step Out)",
      },
      {
        id: 4,
        subtitle: "AI Camera with NFT",
      },
      // {
      //   id: 4,
      //   subtitle: "Launch",
      // },
      // {
      //   id: 5,
      //   subtitle: "DamGo. - Vision Pro and Humane",
      // },
      // {
      //   id: 6,
      //   subtitle:
      //     "United Wallet - Believe to United (Iris scan approved transactions, financial and non-financial tx.)",
      // },
    ],
    class: "right",
  },
];

export default dataRoadMap;
