
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataPortfolio3 = [

    {
        id: 1,
        img: img1,
        step: "01",
        title: 'OWN IT',
        desc: 'Pay once for your United names and own it for a lifetime. No more renting.'
    },
    {
        id: 2,
        img: img2,
        step : "02",
        title: 'WEB3 WEBSITE',
        desc: 'Build your Web3 website with no hosting fees for a lifetime.'
    },
    {
        id: 3,
        img: img3,
        step: "03",
        title: 'NO MORE EXTENSION OR DNS SETTINGS',
        desc: 'DamGo Web3 Browser resolves all United names automatically.'
    },
    {
        id: 4,
        img: img4,
        step: "04",
        title: 'NO MORE @LPHANUMERIC CODES (COMING SOON)',
        desc: 'Receive cryptocurrency payments with your personalized domain names.'
    },


]

export default dataPortfolio3;